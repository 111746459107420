import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private readonly http: HttpClient) { }

  login(obj: any) {
    return this.http.post('/api/Main/login', obj);
  }

  logout() {
    return this.http.post('/api/Main/logout', {});
  }

}
