<mat-toolbar class="flex">
  <div class="small-info flex-1 grid" style="grid-template-columns: min-content 1fr;">
    <span *ngIf="data">WattEx User:</span>
    <span *ngIf="data">{{data.user}}</span>
    <span *ngIf="pingResult">Server Time:</span>
    <span *ngIf="pingResult">{{pingResult.serverTime | date:'h:mm:ss.SSS a'}}</span>
    <span *ngIf="pingResult">Server Latency:</span>
    <span *ngIf="pingResult">{{pingResult.serverLatency}}ms</span>
    <span *ngIf="pingResult">Server Offset:</span>
    <span *ngIf="pingResult">{{pingResult.serverTimeOffset | number:'1.2-2'}}ms</span>
  </div>
  <img src="/ASTRO.svg" class="flex-1 object-scale-down"/>
  <div class="hidden sm:flex justify-end flex-1">
    <a mat-button color="primary" target="_blank" href="/api/main/docs">View Documentation</a>
    <a mat-button color="accent" target="_blank" href="/data">View Results</a>
    <a mat-button (click)="logout()">Logout</a>
  </div>
</mat-toolbar>
<mat-card appearance="outlined" *ngIf="!data || !data.collectedMarketData" class="grid p-2">
  <div class="center">Collecting Market Info...</div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</mat-card>
<div *ngIf="data && data.collectedMarketData" class="flex flex-col sm:flex-row sm:justify-center gap-4">
  <div *ngFor="let group of marketGroups">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <mat-card appearance="outlined" *ngFor="let market of group" class="p-2">
        <mat-card-header>
          <mat-card-title>{{market.marketReference}}</mat-card-title>
          <mat-card-subtitle>
            <div class="flex justify-between">
              <span>
                <i>State:</i> {{market.state}}
                <span *ngIf="market.fired">&nbsp;- Fired</span>
              </span>
              <span>
                <i>Close:</i> {{market.closeTime | date:'short'}}
              </span>
            </div>
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="market.bids.length">
            <div class="flex justify-between">
              <span><i>Bid Volume:</i> {{market.bids[0].volume}}</span>
              <span *ngIf="market.bids[0].price"><i>Bid Price:</i> {{market.bids[0].price}}</span>
            </div>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <astro-main-strategy [marketId]="market.marketId" [isActiveMarket]="market.isActiveMarket"
            [isClosed]="market.state === 'Closed'" [logLink]="getLogLink(market)" [manualMode]="data.manualMode" class="w-full">
          </astro-main-strategy>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>