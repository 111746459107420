<fieldset [formGroup]="batch">
  <legend>Batch {{i+1}}</legend>
  <div class="flex gap-2 justify-between">
    <mat-form-field [class.standby-market]="standbyMarket">
      <input placeholder="Volume" matInput type="number" step="1" formControlName="volume">
      <mat-error *ngIf="batch.hasError('pattern', ['volume'])">must be an integer</mat-error>
      <mat-error *ngIf="batch.hasError('min', ['volume'])">must be at least 5 MW</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!manualMode && !disablePercent">
      <input placeholder="Below Marginal" matInput type="number" step="1" formControlName="percent">
      <span matSuffix>%</span>
      <mat-error *ngIf="batch.hasError('pattern', ['percent'])">must be an integer</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="manualMode" [class.standby-market]="standbyMarket">
      <div matPrefix>$</div>
      <input [placeholder]="standbyMarket ? 'Premium':'Price'" matInput type="number" formControlName="premium">
      <mat-error *ngIf="batch.hasError('required', ['premium'])">required</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="manualMode && standbyMarket" [class.standby-market]="standbyMarket">
      <div matPrefix>$</div>
      <input placeholder="Activation" matInput type="number" formControlName="activation">
      <mat-error *ngIf="batch.hasError('required', ['activation'])">required</mat-error>
    </mat-form-field>
    <button *ngIf="i != 0" mat-icon-button matTooltip="Remove Batch" (click)="removeBatch(i)">
      <mat-icon>delete_outline</mat-icon>
    </button>
    <button *ngIf="i == 0" mat-icon-button matTooltip="Add Batch" (click)="addBatch()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</fieldset>
