import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainService, IMainData, IPingResults, IBiddingMarket } from 'src/app/services/main.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as signalR from '@microsoft/signalr'
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'astro-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss']
})
export class MainScreenComponent implements OnInit, OnDestroy {
  error: string;
  data: IMainData;
  pingResult: IPingResults;

  marketGroups: IBiddingMarket[][] = [];

  subscription: Subscription;

  constructor(
    private readonly service: MainService,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.service.getAllMarketData().subscribe(x => {
      this.data = x;
      this.marketGroups.push(x.markets.filter(y => y.isActiveMarket && !y.marketReference.includes('Regulating')));
      this.marketGroups.push(x.markets.filter(y => !y.isActiveMarket && !y.marketReference.includes('Regulating')));
      const hubConnection = new signalR.HubConnectionBuilder().withUrl('/hub').withAutomaticReconnect().build();
      hubConnection.on('Message',
        msg => {
          if (msg.type === 'ping') {
            this.pingResult = msg.data as IPingResults;
          } else if (msg.type === 'depth' || msg.type === 'state') {
            const grp = msg.data.isActiveMarket ? this.marketGroups[0] : this.marketGroups[1];
            const i = grp.findIndex(y => y.marketId === msg.marketId);
            if (i < 0) {
              console.log(`Not Found: ${msg.marketId}`);
              console.log(this.data.markets);
              return;
            }
            const mkt = grp[i];
            mkt.state = msg.data.state;
            if (!mkt.fired) {
              mkt.bestBid = msg.data.bestBid;
              mkt.bestOffer = msg.data.bestOffer;
              mkt.bids = msg.data.bids;
              mkt.closeTime = msg.data.closeTime;
              mkt.fired = msg.data.fired;
              mkt.isActiveMarket = msg.data.isActiveMarket;
              mkt.marginalOffer = msg.data.marginalOffer;
              mkt.marketId = msg.data.marketId;
              mkt.marketReference = msg.data.marketReference;
              mkt.offers = msg.data.offers;
            }
          } else if (msg.type === 'collectedMarketData') {
            this.data.collectedMarketData = true;
            this.service.getAllMarketData().subscribe(y => this.data = y);
          } else if (msg.type === 'fire') {
            const grp = msg.isActiveMarket ? this.marketGroups[0] : this.marketGroups[1];
            const i = grp.findIndex(y => y.marketId === msg.marketId);
            if (i < 0) {
              console.log(`Not Found: ${msg.marketId}`);
              console.log(this.data.markets);
              return;
            }
            grp[i].fired = true;
          }
        }); // Called whenever there is a message from the server.
      hubConnection.start().catch(err => console.log(err));
    }, (error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.router.navigate(['/login']);
      } else {
        this.error = error.message || error.error;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getLogLink(market: IBiddingMarket) {
    return `/data/${market.closeTime.substring(0, 10)}/${market.marketReference}.csv`;
  }

  logout() {
    this.loginService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    })
  }

}
