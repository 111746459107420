<div>{{title}}</div>
<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="orderId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Id</th>
    <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
  </ng-container>
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
    <td mat-cell *matCellDef="let element"> {{element.price | currency}} </td>
  </ng-container>
  <ng-container matColumnDef="activationPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Activation Price</th>
    <td mat-cell *matCellDef="let element"> {{element.activationPrice | currency}} </td>
  </ng-container>
  <ng-container matColumnDef="blendedPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Blended Price</th>
    <td mat-cell *matCellDef="let element"> {{element.blendedPrice | currency}} </td>
  </ng-container>
  <ng-container matColumnDef="volume">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Volume</th>
    <td mat-cell *matCellDef="let element"> {{element.volume}} </td>
  </ng-container>
  <ng-container matColumnDef="aggregateVolume">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Aggregate Volume</th>
    <td mat-cell *matCellDef="let element"> {{element.aggregateVolume}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
