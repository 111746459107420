import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./components/login/login.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { MainScreenComponent } from "./components/main-screen/main-screen.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatToolbarModule } from "@angular/material/toolbar";
import { OfferListComponent } from "./components/offer-list/offer-list.component";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MainStrategyComponent } from "./components/main-strategy/main-strategy.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { NewStrategyDialogComponent } from "./components/new-strategy-dialog/new-strategy-dialog.component";
import { MatSelectModule } from "@angular/material/select";
import { ActiveFiredOptionsComponent } from "./components/active-fired-options/active-fired-options.component";
import { ActiveUnfiredOptionsComponent } from "./components/active-unfired-options/active-unfired-options.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { StandbyFirstActivationComponent } from "./components/standby-first-activation/standby-first-activation.component";
import { StandbyDefinedActivationComponent } from "./components/standby-defined-activation/standby-defined-activation.component";
import { StandbyDefinedPremiumComponent } from "./components/standby-defined-premium/standby-defined-premium.component";
import { StandbyDefinedActivationOrderComponent } from "./components/standby-defined-activation-order/standby-defined-activation-order.component";
import { BatchComponent } from "./components/batch/batch.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ManualSubmissionComponent } from "./components/manual-submission/manual-submission.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainScreenComponent,
    OfferListComponent,
    MainStrategyComponent,
    NewStrategyDialogComponent,
    ActiveFiredOptionsComponent,
    ActiveUnfiredOptionsComponent,
    StandbyFirstActivationComponent,
    StandbyDefinedActivationComponent,
    StandbyDefinedPremiumComponent,
    StandbyDefinedActivationOrderComponent,
    BatchComponent,
    ManualSubmissionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    AppRoutingModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: "dynamic" },
    },
  ],
})
export class AppModule {}
