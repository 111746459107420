<mat-toolbar class="flex items-center justify-end">
  <div>
    <a mat-button color="primary" target="_blank" href="/api/main/docs">View Documentation</a>
    <a mat-button color="accent" href="/data">View Results</a>
  </div>
</mat-toolbar>
<div class="container flex flex-col items-center justify-center m-auto">
  <img src="/ASTRO.svg" />
  <form [formGroup]="form" (submit)="login()">
    <mat-card appearance="outlined" class="p-2">
      <mat-card-title>WattEx Login</mat-card-title>
      <div class="grid gap-2">
        <mat-form-field>
          <input matInput name="username" placeholder="Username" type="text" formControlName="username">
          <mat-error *ngIf="form.hasError('required', ['username'])">required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput name="password" placeholder="Password" type="password" formControlName="password">
          <mat-error *ngIf="form.hasError('required', ['password'])">required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input class="right" matInput name="fireTime" placeholder="Fire Strategies Before Close" type="number"
            formControlName="fireTime">
          <mat-error *ngIf="form.hasError('required', ['fireTime'])">required</mat-error>
          <span matSuffix>ms</span>
        </mat-form-field>
        <mat-slide-toggle formControlName="manualMode"
          matTooltip="Attempts to run faster by not tracking offers coming in.  Only allows 'Manual Submission' strategies">
          Manual Mode</mat-slide-toggle>
      </div>
      <mat-card-actions>
        <button class="w-full" mat-flat-button color="primary" [disabled]="sending">{{sending ? 'Logging
          In...':'Login'}}</button>
      </mat-card-actions>
    </mat-card>
  </form>
  <div class="error" *ngIf="error" style="color: red">{{error | json}}</div>
</div>
