<h1 mat-dialog-title>Add Strategy</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-select placeholder="Strategy Type" [formControl]="control">
      <mat-option cdkFocusInitial *ngFor="let item of strategies" [value]="item">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button (click)="save()" color="primary"
    [disabled]="!control.value || saving">{{saving ? 'Adding...' :  'Add'}}</button>
</div>
