<div class="flex flex-wrap gap-2">
  <mat-chip-listbox class="flex justify-center items-center" >
    <div class="flex justify-center items-center" *ngFor="let option of options; let first = first; let index = index;">
      <mat-icon *ngIf="!first">arrow_right_alt</mat-icon>
      <mat-chip-option [selected]="option.active" color="accent" (click)="select(index, option)"
        [class.not-selected]="option !== selected">
        <span *ngIf="option.testMode">Test: </span>{{option.type}} - {{getTotal(option)}} MW
      </mat-chip-option>
    </div>
  </mat-chip-listbox>
  <button *ngIf="!isClosed" (click)="add()" color="primary" mat-icon-button matTooltip="Add Strategy">
    <mat-icon>add</mat-icon>
  </button>
</div>
<fieldset *ngIf="manualMode" [formGroup]="prefireForm">
  <legend>Pre-fire Settings</legend>
  <div class="flex gap-2 justify-between">
    <mat-form-field>
      <div matPrefix>$</div>
      <input placeholder="{{isActiveMarket?'Price':'Premium'}}" matInput type="number" step="1" formControlName="price">
    </mat-form-field>
    <mat-form-field *ngIf="!isActiveMarket">
      <div matPrefix>$</div>
      <input placeholder="Activation" matInput type="number" step="1" formControlName="activation">
    </mat-form-field>
    <button mat-stroked-button (click)="savePrefire()"
      [disabled]="prefireForm.invalid || prefireForm.pristine || saving">
      Save
    </button>
  </div>
</fieldset>
<div *ngIf="selected">
  <astro-active-fired-options [options]="selected" [readonly]="isClosed" [key]="marketId" (deleted)="deleted()"
    (closed)="closed()" (updated)="updated($event)" *ngIf="selected.type === 'Fired'"></astro-active-fired-options>
  <astro-active-unfired-options [options]="selected" [readonly]="isClosed" [key]="marketId" (deleted)="deleted()"
    (closed)="closed()" (updated)="updated($event)" *ngIf="selected.type === 'Unfired'"></astro-active-unfired-options>
  <astro-standby-first-activation [options]="selected" [readonly]="isClosed" [key]="marketId" (deleted)="deleted()"
    (closed)="closed()" (updated)="updated($event)" *ngIf="selected.type === 'First Activation'">
  </astro-standby-first-activation>
  <astro-standby-defined-activation [options]="selected" [readonly]="isClosed" [key]="marketId" (deleted)="deleted()"
    (closed)="closed()" (updated)="updated($event)" *ngIf="selected.type === 'Defined Activation'">
  </astro-standby-defined-activation>
  <astro-standby-defined-activation-order [options]="selected" [readonly]="isClosed" [key]="marketId"
    (deleted)="deleted()" (closed)="closed()" (updated)="updated($event)"
    *ngIf="selected.type === 'Defined Activation Order'">
  </astro-standby-defined-activation-order>
  <astro-standby-defined-premium [options]="selected" [readonly]="isClosed" [key]="marketId" (deleted)="deleted()"
    (closed)="closed()" (updated)="updated($event)" *ngIf="selected.type === 'Defined Premium'">
  </astro-standby-defined-premium>
  <astro-manual-submission [options]="selected" [readonly]="isClosed" [key]="marketId" (deleted)="deleted()"
    (closed)="closed()" (updated)="updated($event)" *ngIf="selected.type === 'Manual Submission'"
    [standbyMarket]="!isActiveMarket">
  </astro-manual-submission>
</div>
<a *ngIf="isClosed" mat-button color="primary" [href]="logLink">View Results</a>