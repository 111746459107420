import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private readonly http: HttpClient) { }

  getAllMarketData() {
    return this.http.get<IMainData>('/api/main').pipe(
      map(x => {
        const list: IBiddingMarket[] = [];
        Object.keys(x.markets).forEach((key) => {
          list.push(Object.assign(x.markets[key], { marketId: key }));
        });
        x.markets = list.sort((a, b) => (a.closeTime > b.closeTime) ? 1 : ((b.closeTime > a.closeTime) ? -1
          : (a.marketReference > b.marketReference) ? 1 : ((b.marketReference > a.marketReference) ? -1 : 0)));
        return x;
      })
    );
  }

  getPrefireData(marketId: string) {
    return this.http.get<{ price: number, activation: number }>('api/main/prefire/' + marketId);
  }

  setPrefireData(marketId: string, price: number, activation: number) {
    return this.http.post('api/main/prefire', { marketId, price, activation });
  }
}
export interface IMainData {
  markets: IBiddingMarket[];
  user: string;
  collectedMarketData: boolean;
  manualMode: boolean;
}

interface IMarket {
  marketTemplateId: string;
  productName: string;
  productId: string;
  profileName: string;
  profileId: string;
  contractName: string;
  contractId: string;
  instrumentString: string;
  description: string;
  activationFactor: string;
  isBlendedPricing: boolean;
  state: string;
  marketReference: string;
  closeTime: string;
  deliveryDate: string;
  isClosingSoon: boolean;
  marketId: string;
  volumeBid: string;
  bid: string;
  volumeOffer: string;
  offer: string;
  equPrice: string;
  equVolume: string;
}

export interface IMessage {
  type: string;
  marketId?: string;
  data?: IPingResults | IBiddingMarket;
}

export interface IPingResults {
  serverTime: Date;
  serverLatency: number;
  serverTimeOffset: number;
}

export interface IBiddingMarket {
  closeTime: string;
  state: string;
  marketId: string;
  marketReference: string;
  bids: IOffer[];
  offers: IOffer[];
  bestBid?: IOffer;
  bestOffer?: IOffer;
  marginalOffer?: IOffer;
  isActiveMarket: boolean;

  fired?: boolean;
}

export interface IOffer {
  orderId: string;
  price: string;
  activationPrice: string;
  blendedPrice: string;
  volume: string;
  aggregateVolume: string;
  isMine: boolean;
  companyShortName: string;
}
