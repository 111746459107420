<form [formGroup]="form" class="grid grid-cols-3 gap-2">
  <mat-slide-toggle formControlName="active">Arm Strategy</mat-slide-toggle>
  <mat-slide-toggle matTooltip="Doesn't send offer to WattEx" formControlName="testMode">Test Mode</mat-slide-toggle>
  <mat-form-field>
    <input placeholder="Volume Buffer" matInput type="number" formControlName="volumeBuffer">
    <mat-error *ngIf="form.hasError('required', ['volumeBuffer'])">required</mat-error>
    <div matSuffix>MW</div>
  </mat-form-field>
  <mat-form-field>
    <div matPrefix>$</div>
    <input placeholder="Forecast Pool Price" matInput type="number" formControlName="forecastPoolPrice">
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="Asset" formControlName="assetId">
      <mat-option *ngFor="let item of assets" [value]="item.id">{{item.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="form.hasError('required', ['assetId'])">required</mat-error>
  </mat-form-field>
  <div formArrayName="batches" class="col-span-3">
    <div *ngFor="let batch of getBatches().controls; let i = index;let first = first;">
      <astro-batch [batches]="getBatches()" [batch]="batch" [i]="i"></astro-batch>
    </div>
  </div>
</form>
<div *ngIf="!readonly" class="flex gap-4 justify-start items-center">
  <button *ngIf="!deleteMode" mat-button (click)="deleteMode = true">Delete</button>
  <div *ngIf="deleteMode">Are you sure?</div>
  <button *ngIf="deleteMode" mat-button (click)="deleteMode = false">Cancel</button>
  <button color="primary" *ngIf="deleteMode" mat-button (click)="delete()"
    [disabled]="deleting">{{deleting?'Deleting...':'Delete'}}</button>
  <button *ngIf="!deleteMode" color="primary" mat-raised-button (click)="save()"
    [disabled]="form.invalid || form.pristine || saving">{{saving?'Saving...':'Save'}}</button>
  <div class="w-full"></div>
  <button mat-button (click)="closed.emit()">Close</button>
</div>
<div *ngIf="readonly" class="flex justify-end">
  <button mat-button (click)="closed.emit()">Close</button>
</div>
