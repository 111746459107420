import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { IOffer } from 'src/app/services/main.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'astro-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() title: string;
  @Input() data: IOffer[];
  @Input() columns = ['price', 'activationPrice', 'blendedPrice', 'volume', 'aggregateVolume'];
  dataSource: MatTableDataSource<IOffer>;

  constructor() { }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
  }

}
