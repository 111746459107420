import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { StrategyService } from 'src/app/services/strategy.service';

@Component({
  selector: 'astro-new-strategy-dialog',
  templateUrl: './new-strategy-dialog.component.html',
  styleUrls: ['./new-strategy-dialog.component.scss']
})
export class NewStrategyDialogComponent implements OnInit {
  control = new UntypedFormControl();
  strategies: string[];
  saving = false;

  constructor(
    private readonly dialogRef: MatDialogRef<NewStrategyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { isActiveMarket: boolean, key: string, manualMode: boolean },
    private readonly service: StrategyService
  ) { }

  ngOnInit() {
    if (this.data.manualMode) {
      this.strategies = ['Manual Submission'];
    } else if (this.data.isActiveMarket) {
      this.strategies = ['Peak Fired Backed Volume', 'Unfired Backed Volume', 'Manual Submission'];
    } else {
      this.strategies = ['First Activation Priority', 'Defined Activation', 'Defined Activation Order', 'Defined Premium', 'Manual Submission'];
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.saving = true;
    let obs: Observable<any>;
    const options = { batches: [{ volume: 0, percent: 7 }] };
    switch (this.control.value) {
      case 'Peak Fired Backed Volume':
        obs = this.service.setFiredOptions(this.data.key, options);
        break;
      case 'Unfired Backed Volume':
        obs = this.service.setUnfiredOptions(this.data.key, options);
        break;
      case 'First Activation Priority':
        obs = this.service.setFirstActivationOptions(this.data.key, options);
        break;
      case 'Defined Activation':
        obs = this.service.setDefinedActivationOptions(this.data.key, options);
        break;
      case 'Defined Activation Order':
        obs = this.service.setDefinedActivationOrderOptions(this.data.key, options);
        break;
      case 'Defined Premium':
        obs = this.service.setDefinedPremiumOptions(this.data.key, options);
        break;
      case 'Manual Submission':
        obs = this.service.setManualSubmissionOptions(this.data.key, options);
        options.batches[0].percent = 0;
        break;
    }
    obs.subscribe(x => {
      this.dialogRef.close(true);
      this.saving = false;
    });
  }

}
