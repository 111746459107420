import { Component, OnInit, Input } from '@angular/core';
import { StrategyService, IBaseOptions } from 'src/app/services/strategy.service';
import { MatDialog } from '@angular/material/dialog';
import { NewStrategyDialogComponent } from '../new-strategy-dialog/new-strategy-dialog.component';
import { MainService } from 'src/app/services/main.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'astro-main-strategy',
  templateUrl: './main-strategy.component.html',
  styleUrls: ['./main-strategy.component.scss']
})
export class MainStrategyComponent implements OnInit {
  @Input() marketId: string;
  @Input() isActiveMarket: boolean;
  @Input() isClosed: boolean;
  @Input() logLink: string;
  @Input() manualMode: boolean;
  options: IBaseOptions[];
  selected: IBaseOptions;
  selectedIndex: number;
  prefireForm: UntypedFormGroup;
  saving = false;

  constructor(
    private readonly service: StrategyService,
    private readonly dialog: MatDialog,
    private readonly main: MainService,
    private readonly fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.service.getOptions(this.marketId).subscribe(x => this.options = x);
    this.prefireForm = this.fb.group({
      'price': [null],
      'activation': [null]
    });
    this.main.getPrefireData(this.marketId).subscribe(x => this.prefireForm.patchValue(x));
  }

  savePrefire() {
    const val = this.prefireForm.value;
    this.saving = true;
    this.main.setPrefireData(this.marketId, val.price, val.activation).subscribe(x => {
      this.saving = false;
    })
  }

  add() {
    const dialog = this.dialog.open(NewStrategyDialogComponent,
      { data: { isActiveMarket: this.isActiveMarket, key: this.marketId, manualMode: this.manualMode } });
    dialog.afterClosed().subscribe(x => {
      if (x) {
        this.ngOnInit();
      }
    });
  }

  select(index: number, option: IBaseOptions) {
    this.selectedIndex = index;
    this.selected = option;
  }

  deleted() {
    this.options.splice(this.selectedIndex, 1);
    this.selected = null;
  }

  closed() {
    this.selected = null;
  }

  updated(option: IBaseOptions) {
    this.options[this.selectedIndex] = option;
    this.selected = option;
  }

  getTotal(option: IBaseOptions) {
    let acc = 0;
    if (option.batches) {
      option.batches.forEach(x => acc += x.volume);
    }
    return acc;
  }
}
