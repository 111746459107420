import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IFiredOptions, StrategyService, ILookup } from 'src/app/services/strategy.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'astro-active-fired-options',
  templateUrl: './active-fired-options.component.html',
  styleUrls: ['./active-fired-options.component.scss']
})
export class ActiveFiredOptionsComponent implements OnInit, OnChanges {
  @Input() options: IFiredOptions;
  @Input() key: string;
  @Input() readonly: boolean;
  @Output() updated = new EventEmitter<IFiredOptions>();
  @Output() deleted = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();
  form: UntypedFormGroup;
  saving = false;
  deleting = false;
  deleteMode = false;

  assets: ILookup[] = [];

  constructor(
    private readonly service: StrategyService,
    private readonly fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      'id': [null, Validators.required],
      'active': [false, Validators.required],
      'testMode': [false, Validators.required],
      'assetId': [null, Validators.required],
      'type': [null, Validators.required],
      'forecastPoolPrice': [0, Validators.required],
      'volumeBuffer': [0, Validators.required],
      'batches': this.fb.array([])
    });
    this.patchForm();
    this.service.getAssets().subscribe(x => this.assets = x);
  }

  ngOnChanges() {
    if (this.form) {
      this.patchForm();
    }
  }

  patchForm() {
    this.form.patchValue(this.options);
    const batches = this.getBatches();
    if (batches) {
      batches.clear();
      this.options.batches.forEach(x => {
        batches.push(this.fb.group({
          'volume': [x.volume, [Validators.required, Validators.pattern('\\d+'), Validators.min(5)]],
          'percent': [x.percent, Validators.required],
        }));
      });
    }
    if (this.readonly) {
      this.form.disable();
    } else {
      this.form.markAllAsTouched();
    }
  }

  save() {
    this.saving = true;
    this.service.setFiredOptions(this.key, this.form.value).subscribe(x => {
      this.saving = false;
      this.updated.emit(this.form.value);
    });
  }

  delete() {
    this.deleting = true;
    this.service.deleteOption(this.key, this.options.id).subscribe(x => {
      this.deleted.emit();
    });
  }

  getBatches() {
    return this.form.get('batches') as UntypedFormArray;
  }
}
