import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StrategyService {

  constructor(private readonly http: HttpClient) { }

  getOptions(key: string) {
    return this.http.get<IBaseOptions[]>(`/api/Strategy/${key}`);
  }

  getAssets() {
    return this.http.get<ILookup[]>('/api/Strategy/Assets');
  }

  deleteOption(key: string, id: string) {
    return this.http.delete(`/api/Strategy/${key}/${id}`);
  }

  reorderOptions(key: string, ids: string[]) {
    return this.http.post(`/api/Strategy/Reorder/${key}`, ids);
  }

  setFiredOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetFiredOptions/${key}`, options);
  }

  setUnfiredOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetUnfiredOptions/${key}`, options);
  }

  setFirstActivationOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetFirstActivationOptions/${key}`, options);
  }

  setDefinedActivationOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetDefinedActivationOptions/${key}`, options);
  }

  setDefinedActivationOrderOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetDefinedActivationOrderOptions/${key}`, options);
  }

  setDefinedPremiumOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetDefinedPremiumOptions/${key}`, options);
  }

  setManualSubmissionOptions(key: string, options: any) {
    return this.http.post(`/api/Strategy/SetManualSubmissionOptions/${key}`, options);
  }
}

export interface ILookup {
  id: string;
  name: string;
}

export interface IBatchOptions {
  volume: number;
  percent: number;
  activation?: number;
  premium?: number;
}

export interface IBaseOptions {
  active: boolean;
  assetId: string;
  id: string;
  type: string;
  testMode: boolean;
  batches: IBatchOptions[];
  volumeBuffer: number;
}

export interface IFiredOptions extends IBaseOptions {
  forecastPoolPrice: number;
}

export interface IUnfiredOptions extends IBaseOptions {
  minIndex: number;
}

export interface IDefinedActivation extends IBaseOptions {
  activation: number;
  minPremium?: number;
}

export interface IDefinedPremium extends IBaseOptions {
  premium: number;
  minActivation?: number;
}
