import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'astro-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {
  @Input() i: number;
  @Input() batches: UntypedFormArray;
  @Input() batch: UntypedFormGroup;
  @Input() manualMode = false;
  @Input() standbyMarket = false;
  @Input() disablePercent = false;

  constructor(private readonly fb: UntypedFormBuilder) { }

  ngOnInit() {
  }

  addBatch() {
    if (this.manualMode) {
      this.fb.group({
        'volume': [0, [Validators.required, Validators.pattern('\\d+'), Validators.min(5)]],
        'percent': 0,
        'activation': [0, Validators.required],
        'premium': [0, Validators.required],
      });
    } else {
      this.batches.push(this.fb.group({
        'volume': [0, [Validators.required, Validators.pattern('\\d+'), Validators.min(5)]],
        'percent': [0, Validators.required],
      }));
    }
    this.batches.markAsDirty();
  }

  removeBatch(i: number) {
    this.batches.removeAt(i);
    this.batches.markAsDirty();
  }

}
