import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'astro-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  error: string;
  sending = false;
  // outsideHours = true;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly service: LoginService,
    private readonly router: Router
  ) {
    this.form = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      fireTime: [100, Validators.required],
      manualMode: [false, Validators.required]
    });
  }

  ngOnInit() {
    // const hour = new Date().getHours();
    // this.outsideHours = hour < 8 || hour > 11;
  }

  login() {
    if (this.form.valid) {
      this.sending = true;
      this.service.login(this.form.value).subscribe(x => {
        this.error = null;
        this.router.navigate(['/']);
      }, (error) => {
        this.error = error.message || error.error;
        this.sending = false;
      });
    }
  }

}
